<template>
  <div>
    <apexchart
      type="area"
      width="100%"
      height="250"
      :options="options"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
export default {
  title: "Menaget - Manage your Skills",
  name: "AreaChart",
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    series: {
      type: Array,
      default: () => [],
    },
    lineColors: {
      type: Array,
      default: () => [],
    },
    fillColors: {
      type: Array,
      default: () => [],
    },
    categories: {
      type: Array,
      default: () => [],
    },
    title: {
      type: Object,
      default: () => {
        return {
          x: "x",
          y: "y",
        };
      },
    },
  },
  computed: {
    options() {
      return {
        chart: {
          id: "area-chart",
          type: "area",
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        colors: this.lineColors,
        xaxis: {
          categories: this.categories,
          title: {
            text: this.title.x
          },
        },
        yaxis: {
          min: 0,
          max: 100,
          title: {
            text: this.title.y,
          },
          tickAmount: 5,
        },
        fill: {
          colors: this.fillColors,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "straight",
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
