<template>
  <div class="dashboard-content">
    <div class="assessment-details bg-light px-md-5 pb-5 my-5">
      <h1 class="assessment-details__title py-4 font-weight-bold">Assessment Details</h1>
      <div class="row justify-content-between mt-5">
        <div v-if="assessment.has_mcq" :class="leftBoxClasses">
          <div class="assessment-details__card card px-2 px-md-4 m-rounded-12">
            <h6 class="assessment-details__card-title py-4">
              Mcq Result: {{ assessment.latest_mcq.score }}%
            </h6>
            <div class="d-flex justify-content-between flex-wrap pt-3">
              <div
                class="assessment-details__test-info align-self-center d-flex flex-column"
              >
                <h4 class="assessment-details__test-info-title">
                  {{ assessment.skill.name }}: {{ assessment.level | capitalize }}
                </h4>
                <p class="assessment-details__date-taken">
                  Test Taken: {{ assessment.latest_mcq.ended_at }}
                </p>
              </div>
              <div>
                <b-button
                  v-if="retakeMcq"
                  class="btn-secondary text-decoration-none text-light px-5"
                >
                  Retake Test
                </b-button>
                <p v-if="showRetakeText" class="m-fs-14">
                  Come back for a retake in {{ assessment.available_in }} day(s).
                </p>
              </div>
            </div>
            <p class="assessment-details__graph-title font-weight-bold mt-2">
              Your results ranking compared to all other Engineers
            </p>
            <AreaChart
              :series="areaSeries"
              :fill-colors="fillColors"
              :line-colors="lineColors"
              :categories="categories"
              :title="title"
            />
            <div class="pt-1">
              <h6 class="assessment-details__progress-title">Track your progress</h6>
              <p class="assessment-details__progress-info">
                Last time, you did better than {{ 100 - mcqScores.in_top }}% of your
                peers.
              </p>
            </div>
          </div>
        </div>
        <div :class="rightBoxClasses">
          <div class="assessment-details__card card px-2 px-md-4 m-rounded-12">
            <h6 class="assessment-details__card-title font-weight-bold py-4">
              Your Progress
            </h6>
            <ProgressTracker :stages="stages" />
            <p class="mt-3 mt-lg-4">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Metus non tincidunt
              ornare sollicitudin egestas consequat interdum elit ut.
            </p>
            <p class="mt-2 mt-lg-3">
              viverra. Semper at tellus pellentesque mi massa pretium risus. Sit habitasse
              ligula penatibus velit.
            </p>
            <div class="d-flex justify-content-center align-items center">
              <b-button
                v-if="continueAssessment"
                variant="primary"
                class="text-decoration-none px-5 mt-2 mw-75"
                @click="nextAssessment()"
              >
                Continue Assessment
              </b-button>
              <p v-if="testUnderReviewing" class="m-fs-18 font-weight-bold">
                Your test is currently being reviewed. We'll reach out to you via email
                as soon as it's done.
              </p>
              <p v-if="showRetakeText" class="m-fs-14">
                Come back for a retake in {{ assessment.available_in }} day(s).
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AreaChart from "../../components/AreaChart.vue";
import ProgressTracker from "../../components/skills/ProgressTracker.vue";
import { mapState, mapActions, mapMutations } from "vuex";
export default {
  title: "Menaget - Assessment Details",
  components: {
    AreaChart,
    ProgressTracker,
  },

  data() {
    return {
      assessments: require("@/data/assessments.json"),
      fillColors: ["#08569D", "transparent"],
      lineColors: ["#08569D"],
    };
  },
  computed: {
    ...mapState({
      assessment: (state) => state.skill.assessment,
      mcqScores: (state) => state.skill.mcqScores,
    }),
    areaSeries() {
      return [
        {
          name: "Candidates",
          data: Object.values(this.mcqScores.scores),
        },
      ];
    },
    retakeMcq() {
      return this.assessment.has_mcq && !this.assessment.assessed.includes("mcq");
    },
    showRetakeText() {
      return (
        this.assessment.has_mcq &&
        this.assessment.latest_mcq.status === 2 &&
        !this.retakeMcq
      );
    },
    categories() {
      return Object.keys(this.mcqScores.scores);
    },
    title() {
      return {
        y: "Candidate (%)",
        x: "Score Range (%)",
      };
    },
    leftBoxClasses() {
      if (this.assessment.has_mcq) return ["col-12", "mb-3", "mb-xl-0", "col-xl-6"];
      return ["col-12"];
    },
    rightBoxClasses() {
      if (this.assessment.has_mcq) return ["col-12", "col-xl-6"];
      return ["col-12"];
    },
    stages() {
      return this.assessment.skill.assessments.map((assessment) => {
        return {
          id: assessment,
          title: this.assessments[assessment],
          description: "",
          status: this.assessment.assessed.includes(assessment) ? "completed" : "pending",
        };
      });
    },
    continueAssessment() {
      return (
        this.assessment.wait_time <= 0 &&
        !this.assessment.has_skill &&
        this.assessment.assessed.length < this.assessment.skill.assessments.length &&
        this.assessment?.latest_test?.status_readable !== "reviewing"
      );
    },
    testUnderReviewing() {
      return this.assessment?.latest_test?.status_readable === 'reviewing'
    }
  },
  created() {
    this.fetchAssessment(this.$route.params.id).then(() => {
      this.setPageInformation({
        title: `${this.assessment.skill.name} Skill Assessment`,
        description: this.assessment.skill.description,
      });
    });

    this.fetchMcqScores(this.$route.params.id);
  },
  methods: {
    ...mapActions({
      fetchAssessment: "skill/fetchAssessment",
      fetchMcqScores: "skill/fetchMcqScores",
      initializeTestAssessment: "skill/initializeTestAssessment",
    }),
    ...mapMutations({
      setPageInformation: "SET_PAGE_INFORMATION",
    }),
    nextAssessment() {
      let nextAssessment = this.assessment.skill.assessments[
        this.assessment.assessed.length
      ];
      if (nextAssessment === "interview") {
        // do something
      }
      if (
        nextAssessment === "test" && (!this.assessment?.ongoing_test ||
        this.assessment?.ongoing_test?.start_time === null)
      ) {
        this.initializeTestAssessment(this.$route.params.id).then(() => {
          this.$router.push(`/skills/${this.$route.params.id}/test`);
        });
      }
      if (
        nextAssessment === "test" &&
        this.assessment?.ongoing_test.start_time !== null
      ) {
        this.$router.push(`/skills/${this.$route.params.id}/test`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.assessment-details {
  &__title {
    font-size: 1.8rem;
    border-bottom: 2px solid $border-color;
  }

  &__card {
    min-height: 35rem;
  }

  &__card-title {
    border-bottom: 2px solid $border-color;
  }

  &__test-info {
    width: 60%;
  }

  &__graph-title {
    font-size: 0.75rem;
    color: $grey-tint-8;
  }

  &__test-info-title {
    font-weight: 700;
  }

  &__date-taken,
  &__progress-info {
    color: $grey-tint-7;
    font-size: 0.875rem;
  }

  &__progress-title {
    color: $deep-blue;
  }
}
</style>
